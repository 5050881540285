
// customize some Bootstrap variables
$primary: #F0BC36;

$gray-800 : #331c11;

// the ~ allows you to reference things in node_modules
@import "~normalize.css";
@import "~bootstrap/scss/bootstrap";
@import "~toastr/toastr";
@import "~chosen-js/chosen.min.css";

.sidebar {
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar .nav-link.active {
  color: $gray-800;
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

/* Login page
-------------------------------------------------- */
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Button circle
-------------------------------------------------- */
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

/* Custom chosen select inputs
-------------------------------------------------- */
.chosen-container,
.chosen-single,
.chosen-choices {
  width: 100%;
  font-size: 18px;
  border: 1px solid transparent;
  background-color: #f4f4f4;
  color: #282828;
  padding: 10px 15px;
}

.chosen-container:focus {
  outline: none;
  border: 1px solid $primary;
}

.chosen-container .chosen-drop{
  left: 0;
}
.chosen-container-active.chosen-with-drop .chosen-single{
  border-color: transparent;
  background-image: none;
  box-shadow: none;
}
.chosen-container .chosen-results li.highlighted{
  background-color: $primary;
  background-image: none;
}
.chosen-single,
.chosen-choices,
.chosen-container-single .chosen-single{
  background-image: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  border: none !important;
  font-size: 16px !important;
}
.chosen-container-multi .chosen-single li.search-choice,
.chosen-container-multi .chosen-choices li.search-choice{
  background-color: $primary;
  font-weight: bold;
  color: #fff;
  padding: 8px 25px 8px 15px;
  border: none;
  background-image: none;
  box-shadow: none;
  line-height: 15px;
}
.chosen-container span,
.chosen-container-single .chosen-single span{
  margin: 0;
}